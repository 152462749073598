<script lang="ts">
	import { page } from '$app/stores'
	import {
		get_modal_state,
		get_mobile_nav_state,
		get_nav_submenu_state,
		set_mobile_nav_state,
		set_nav_submenu_state,
	} from '$lib/components/context'
	import { Button, Navigation, SubMenu, MobileSubMenu } from '$lib/components'

	set_mobile_nav_state()
	set_nav_submenu_state()

	let is_mobile_nav_open = get_mobile_nav_state()
	let is_sub_menu_open = get_nav_submenu_state()
	let is_modal_open = get_modal_state()

	$: ({ categories, teasers } = $page.data)
</script>

<Navigation logo="white">
	<div
		slot="menu"
		class="hidden gap-x-8 text-lg font-medium tracking-tight text-white lg:flex lg:w-auto"
	>
		<SubMenu>
			<svelte:fragment slot="menu-items">
				{#each categories as category}
					<a
						on:click={() => {
							$is_sub_menu_open = false
						}}
						href="/ratgeber/{category.slug}"
						class="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
					>
						<div class="h-6 w-6 shrink-0">{category.emoji}</div>
						<div class="ml-4">
							<p class="text-base font-medium text-gray-900">{category.title}</p>
							<p class="mt-1 hidden text-sm font-normal text-gray-500 md:inline">
								{category.description}
							</p>
						</div>
					</a>
				{/each}
			</svelte:fragment>
			<div slot="teaser" class="hidden bg-gray-50 px-5 py-5 sm:px-8 sm:py-8 md:block">
				<div>
					<h3 class="text-sm font-medium uppercase tracking-wide text-gray-500">
						Unsere neuesten Beiträge
					</h3>
					<ul class="mt-4">
						{#each teasers as teaser}
							<li>
								<a
									on:click={() => {
										$is_sub_menu_open = false
									}}
									href={`/ratgeber/${teaser.categories[0].slug}/${teaser.url}`}
									class="fadeout relative block w-full truncate py-3 text-sm font-normal text-gray-900 hover:text-orange-600"
									title={teaser.title}
								>
									&rarr; {teaser.title.slice(0, 200) + '…'}
								</a>
							</li>
						{/each}
					</ul>
				</div>
			</div>
		</SubMenu>

		<a href="/erfahrungen" class="hidden text-white hover:text-orange-600 lg:block">Kundenstimmen</a
		>
		<a href="/karriere" class="hidden text-white hover:text-orange-600 lg:block"
			>Karriere bei hiral&nbsp; 🚀</a
		>
	</div>
	<div
		slot="actions"
		class="hidden w-auto max-w-sm gap-4 text-white lg:grid xl:max-w-2xl xl:grid-cols-2"
	>
		<a
			href="/kandidaten"
			class="btn secondary white hidden w-full hover:bg-white hover:bg-opacity-10 xl:block"
			on:click={() => ($is_modal_open = false)}>Ich bin Kandidat</a
		>
		<Button on:click={() => ($is_modal_open = true)}>Kostenloser Stellencheck</Button>
	</div>
	<svelte:fragment slot="mobile-links">
		<MobileSubMenu>
			<svelte:fragment slot="title">
				<div
					class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
				>
					💡
				</div>
				<span class="ml-4 text-base font-medium text-gray-900">Ratgeber</span>
				<svg
					class="ml-2 h-5 w-5 group-hover:text-orange-600"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
						clip-rule="evenodd"
					/>
				</svg>
			</svelte:fragment>
			<svelte:fragment slot="menu-items">
				{#each categories as category}
					<a
						on:click={() => {
							$is_sub_menu_open = false
							$is_mobile_nav_open = false
						}}
						href="/ratgeber/{category.slug}"
						class="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
					>
						<div class="h-6 w-6 shrink-0">{category.emoji}</div>
						<div class="ml-4">
							<p class="text-base font-medium text-gray-900">{category.title}</p>
							<p class="mt-1 hidden text-sm font-normal text-gray-500 md:inline">
								{category.description}
							</p>
						</div>
					</a>
				{/each}
			</svelte:fragment>
		</MobileSubMenu>
		<a
			on:click={() => ($is_mobile_nav_open = false)}
			href="/erfahrungen"
			class="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
		>
			<div
				class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
			>
				🎙
			</div>
			<div class="ml-4 text-base font-medium text-gray-900">Kundenstimmen</div>
		</a>
		<a
			on:click={() => ($is_mobile_nav_open = false)}
			href="/karriere"
			class="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
		>
			<div
				class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
			>
				🚀
			</div>
			<div class="ml-4 text-base font-medium text-gray-900">Karriere bei hiral</div>
		</a>
		<a
			on:click={() => ($is_mobile_nav_open = false)}
			href="/kontakt"
			class="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
		>
			<div
				class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
			>
				☎️
			</div>
			<div class="ml-4 text-base font-medium text-gray-900">Kontakt</div>
		</a>
	</svelte:fragment>
	<svelte:fragment slot="mobile-actions">
		<Button
			on:click={() => {
				$is_mobile_nav_open = false
				$is_modal_open = true
			}}
			class="w-full">Kostenloser Stellencheck</Button
		>
		<a
			href="/kandidaten"
			on:click={() => ($is_mobile_nav_open = false)}
			class="btn secondary w-full">Ich bin Kandidat</a
		>
	</svelte:fragment>
</Navigation>
